<template>
  <router-view />
</template>

<script>
import Nprogress from "nprogress";
export default {
  name: "App",
  watch: {
    request: function (val) {
      if (val === 1) {
        Nprogress.start();
      }
      if (val === 0) {
        Nprogress.done();
      }
    },
  },
  computed: {
    request() {
      return this.$store.getters["app/getRequestCount"];
    },
    loggedIn: function () {
      return this.$store.getters["user/loggedIn"];
    },
  },
  created() {
    if (this.loggedIn) {
      this.$store.dispatch("user/getData");
    }
  },
};
</script>

<style>
#nprogress .spinner-icon {
  border-top-color: red !important;
  border-left-color: red !important;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.05s ease;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-to {
  opacity: 1;
}

.fade-leave-active {
  transition: all 0.05s ease;
}
</style>
