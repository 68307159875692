const routes = [
  {
    path: "/",
    component: () => import("@/layouts/OrangeLayout"),
    redirect: { name: "home" },
    children: [
      {
        path: "index",
        name: "home",
        meta: {
          requiresAuth: false,
        },
        component: () => import("@/views/index"),
      },

      /**
       * Url routes for Auth
       */
      {
        path: "login",
        name: "login",
        meta: {
          requiresAuth: false,
          onlyGuest: true,
        },
        component: () => import("@/views/auth/login"),
      },
      {
        path: "register",
        name: "register",
        meta: {
          requiresAuth: false,
          onlyGuest: true,
        },
        component: () => import("@/views/auth/register"),
      },
      {
        path: "confirmation",
        name: "confirmation",
        meta: {
          requiresAuth: false,
          onlyGuest: true,
        },
        component: () => import("@/views/auth/confirmation"),
      },
      {
        path: "finish",
        name: "finish",
        meta: {
          requiresAuth: false,
          onlyGuest: true,
        },
        component: () => import("@/views/auth/finish"),
      },
      {
        path: "recover",
        name: "recover",
        meta: {
          requiresAuth: false,
          onlyGuest: true,
        },
        component: () => import("@/views/auth/recover"),
      },

      /**
       * Url routes for Display
       */
      {
        path: "display",
        name: "display_index",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/index"),
      },
      {
        path: "display/plus/settings",
        name: "display_plus_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/plus/settings"),
      },
      /*{
        path: "display/plus/start",
        name: "display_plus_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/plus/start"),
      },*/
      {
        path: "display/pro/settings",
        name: "display_pro_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/pro/settings"),
      },
      /*{
        path: "display/pro/start",
        name: "display_pro_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/pro/start"),
      },*/
      {
        path: "display/multiply/settings",
        name: "display_multiply_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/multiply/settings"),
      },
      /*{
        path: "display/multiply/start",
        name: "display_multiply_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/multiply/start"),
      },*/
      {
        path: "display/divide/settings",
        name: "display_divide_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/divide/settings"),
      },
      /*{
        path: "display/divide/start",
        name: "display_divide_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/divide/start"),
      },*/
      {
        path: "display/root/settings",
        name: "display_root_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/root/settings"),
      },
      /*{
        path: "display/root/start",
        name: "display_root_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/root/start"),
      },*/

      /**
       * Url routes for Record
       */
      {
        path: "record",
        name: "record_index",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/index"),
      },
      {
        path: "record/display",
        name: "record_display",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/display"),
      },
      {
        path: "record/list",
        name: "record_list",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/list"),
      },
      {
        path: "record/display/plus",
        name: "record_display_plus",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/display/plus"),
      },
      {
        path: "record/display/pro",
        name: "record_display_pro",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/display/pro"),
      },
      {
        path: "record/display/multiply",
        name: "record_display_multiply",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/display/multiply"),
      },
      {
        path: "record/display/divide",
        name: "record_display_divide",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/display/divide"),
      },
      {
        path: "record/display/root",
        name: "record_display_root",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/display/root"),
      },
      /**
       *  Record for List
       */
      {
        path: "record/list/plus",
        name: "record_list_plus",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/list/plus"),
      },
      {
        path: "record/list/pro",
        name: "record_list_pro",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/list/pro"),
      },
      {
        path: "record/list/multiply",
        name: "record_list_multiply",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/list/multiply"),
      },
      {
        path: "record/list/divide",
        name: "record_list_divide",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/list/divide"),
      },
      {
        path: "record/list/root",
        name: "record_list_root",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/record/list/root"),
      },

      /**
       * Url for Generator
       */
      {
        path: "generator",
        name: "generator",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/generator/index"),
      },
      {
        path: "generator/plus",
        name: "generator_plus",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/generator/plus"),
      },
      {
        path: "generator/pro",
        name: "generator_pro",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/generator/pro"),
      },
      {
        path: "generator/multiply",
        name: "generator_multiply",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/generator/multiply"),
      },
      {
        path: "generator/divide",
        name: "generator_divide",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/generator/divide"),
      },
      {
        path: "generator/root",
        name: "generator_root",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/generator/root"),
      },

      /**
       * Url for List
       */
      {
        path: "list",
        name: "list",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/index"),
      },

      {
        path: "list/plus/settings",
        name: "list_plus_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/plus/settings"),
      },
      {
        path: "list/plus/start",
        name: "list_plus_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/plus/start"),
      },

      {
        path: "list/pro/settings",
        name: "list_pro_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/pro/settings"),
      },
      {
        path: "list/pro/start",
        name: "list_pro_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/pro/start"),
      },

      {
        path: "list/multiply/settings",
        name: "list_multiply_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/multiply/settings"),
      },
      {
        path: "list/multiply/start",
        name: "list_multiply_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/multiply/start"),
      },

      {
        path: "list/divide/settings",
        name: "list_divide_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/divide/settings"),
      },
      {
        path: "list/divide/start",
        name: "list_divide_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/divide/start"),
      },

      {
        path: "list/root/settings",
        name: "list_root_settings",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/root/settings"),
      },
      {
        path: "list/root/start",
        name: "list_root_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/list/root/start"),
      },

      /**
       * Other user pages
       */
      {
        path: "user/order",
        name: "user_order",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/user/order"),
      },
      {
        path: "user/account",
        name: "user_account",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/user/account"),
      },
    ],
  },
  {
    path: "/new",
    component: () => import("@/layouts/DisplayLayout"),
    redirect: { name: "display_plus_start" },
    children: [
      {
        path: "/display/plus/start",
        name: "display_plus_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/plus/start2"),
      },
      {
        path: "/display/pro/start",
        name: "display_pro_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/pro/start2"),
      },
      {
        path: "/display/multiply/start",
        name: "display_multiply_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/multiply/start2"),
      },
      {
        path: "/display/divide/start",
        name: "display_divide_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/divide/start2"),
      },
      {
        path: "/display/root/start",
        name: "display_root_start",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/display/root/start2"),
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "home" },
  },
];

export default routes;
