import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "nprogress/nprogress.css";
import toast from "@/helpers/toast";
import VueTheMask from "vue-the-mask";
import filters from "@/helpers/filters";

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.mixin({
  filters: filters,
});

new Vue({
  toast,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
