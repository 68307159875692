import apiClient from "@/helpers/ApiClient";

const state = () => ({
  isReady: false,
  settings: {
    number_one_length: 1,
    number_two_length: 1,
    number_row: 10,
    number_speed: 30,
  },
  complete: {
    id: "",
    attempts: 1,
    answer: 0,
    number_row: 0,
  },
  numbers: [],
  record: {
    display: {
      settings: null,
      data: [],
    },
    list: {
      settings: null,
      data: [],
    },
  },
  generated_numbers: "",
  list: {
    isReady: false,
    settings: null,
    numbers: null,
    complete: {
      id: null,
      answer: 0,
    },
  },
});

const mutations = {
  INC_LIST_ANSWER(state) {
    state.list.complete.answer++;
  },
  SET_GENERATED_NUMBERS(state, numbers) {
    state.generated_numbers = numbers;
  },
  SET_RECORD_SETTINGS(state, settings) {
    state.record.display.settings = settings;
  },
  SET_RECORD_DATA(state, records) {
    state.record.display.data = records;
  },
  SET_LIST_RECORD_SETTINGS(state, settings) {
    state.record.list.settings = settings;
  },
  SET_LIST_RECORD_DATA(state, records) {
    state.record.list.data = records;
  },
  SET_NUMBERS(state, numbers) {
    state.numbers = numbers;
  },
  ADD_NUMBERS(state, new_numbers) {
    state.numbers.push(...new_numbers);
  },
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_STATUS(state, status) {
    state.isReady = status;
  },
  INC_ANSWER(state) {
    state.complete.answer = parseInt(state.complete.answer) + 1;
  },
  INC_NUMBER_ROW(state) {
    state.complete.number_row = parseInt(state.complete.number_row) + 1;
  },
  SET_ID(state, id) {
    state.complete.id = id;
  },
  CLEAR_COMPLETE(state) {
    state.complete = {
      id: "",
      attempts: 1,
      answer: 0,
      number_row: 0,
    };
  },
  CLEAR_DATA(state) {
    state.isReady = false;
    state.settings = {
      number_one_length: 1,
      number_two_length: 1,
      number_row: 10,
      number_speed: 30,
    };
    state.complete = {
      id: "",
      attempts: 1,
      answer: 0,
      number_row: 0,
    };
    state.numbers = [];
    state.list = {
      isReady: false,
      settings: null,
      numbers: null,
      complete: {
        id: null,
        answer: 0,
      },
    };
  },
};

const actions = {
  async prepare(context, data) {
    return await apiClient
      .post("display/divide-prepare", data)
      .then((response) => {
        context.commit("SET_SETTINGS", {
          number_one_length: response.data.number_one_length,
          number_two_length: response.data.number_two_length,
          number_row: response.data.number_row,
          number_speed: response.data.number_speed,
        });
        context.commit("SET_NUMBERS", response.data.numbers);
        context.commit("SET_STATUS", true);
        context.commit("SET_ID", response.data.id);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async add_new_numbers(context) {
    return await apiClient
      .post("display/get-divide-numbers", context.state.settings)
      .then((response) => {
        context.commit("ADD_NUMBERS", response.data.numbers);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async complete(context) {
    return await apiClient
      .post("display/divide-complete", context.state.complete)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async setRecord(context, settings) {
    await context.commit("SET_RECORD_SETTINGS", settings);
    return await apiClient
      .post("record/divide", settings)
      .then(async (response) => {
        await context.commit("SET_RECORD_DATA", response.data);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async setListRecord(context, settings) {
    await context.commit("SET_LIST_RECORD_SETTINGS", settings);
    return await apiClient
      .post("record/list-divide", settings)
      .then(async (response) => {
        await context.commit("SET_LIST_RECORD_DATA", response.data);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async generate(context, settings) {
    return await apiClient
      .post("generator/divide", settings)
      .then(async (response) => {
        await context.commit("SET_GENERATED_NUMBERS", response.data);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async listPrepare(context, data) {
    return await apiClient
      .post("list/divide-prepare", data)
      .then((response) => {
        context.state.list.settings = {
          number_one_length: response.data.number_one_length,
          number_two_length: response.data.number_two_length,
          number_row: response.data.number_row,
          number_count: response.data.number_count,
          number_speed: response.data.number_speed,
        };
        context.state.list.numbers = response.data.numbers;
        context.state.list.complete.id = response.data.id;
        context.state.list.isReady = true;

        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async listComplete(context) {
    return await apiClient
      .post("list/divide-complete", context.state.list.complete)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
};

const getters = {
  state(state) {
    return state;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
