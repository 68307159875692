const state = () => ({
  language: "uz",
  requestCount: 0,
});

const mutations = {
  INC_REQUEST_COUNT(state) {
    state.requestCount++;
  },
  DEC_REQUEST_COUNT(state) {
    state.requestCount--;
  },
};

const actions = {
  inc_request(context) {
    context.commit("INC_REQUEST_COUNT");
  },
  dec_request(context) {
    context.commit("DEC_REQUEST_COUNT");
  },
};

const getters = {
  getRequestCount(state) {
    return state.requestCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
