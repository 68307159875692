import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user";
import app from "@/store/modules/app";
import game from "@/store/modules/game";
import plus from "@/store/modules/plus";
import pro from "@/store/modules/pro";
import multiply from "@/store/modules/multiply";
import divide from "@/store/modules/divide";
import root from "@/store/modules/root";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    game,
    plus,
    pro,
    multiply,
    divide,
    root,
  },
});
