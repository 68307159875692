import Vue from "vue";
import apiClient from "@/helpers/ApiClient";
import router from "@/router";

const state = () => ({
  token: localStorage.getItem("access_token") || null,
  user: {},
  temp_token: "",
  order: {
    access: "",
    info: "",
    data: "",
  },
  click: null,
});

const mutations = {
  SET_CLICK_DATA(state, data) {
    state.click = data;
  },
  SET_ORDER_ACCESS(state, status) {
    state.order.access = status;
  },
  SET_ORDER_INFO(state, info) {
    state.order.info = info;
  },
  SET_ORDER_DATA(state, data) {
    state.order.data = data;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem("access_token", token);
  },
  DESTROY_TOKEN(state) {
    state.token = null;
  },
  SET_TEMP_TOKEN(state, token) {
    state.temp_token = token;
  },
};

const actions = {
  async recover(context, phone) {
    return await apiClient
      .post("user/recover", {
        phone: phone,
      })
      .then((response) => {
        context.commit("SET_TEMP_TOKEN", response.data.token);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async register_phone(context, phone) {
    return await apiClient
      .post("user/signup", {
        phone: phone,
      })
      .then((response) => {
        context.commit("SET_TEMP_TOKEN", response.data.token);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async confirm_phone(context, code) {
    return await apiClient
      .post("user/confirm-code", {
        token: context.getters.getTempToken,
        code: code,
      })
      .then((response) => {
        context.commit("SET_TEMP_TOKEN", response.data.token);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async send_sms(context) {
    return await apiClient
      .post("sms/send", {
        auth_key: context.getters.getTempToken,
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async finish(context, data) {
    return await apiClient
      .post("user/finish", data)
      .then(({ data }) => {
        context.commit("SET_TOKEN", data.token);
        return true;
      })
      .catch((error) => {
        Vue.$toast.error(error.response.data.message);
        return false;
      });
  },
  async setUser(context) {
    await apiClient
      .post("user/get-data")
      .then((response) => {
        context.commit("SET_USER", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getData(context) {
    return await apiClient
      .post("user/get-data", {
        token: context.getters.getToken,
      })
      .then((response) => {
        context.commit("SET_USER", response.data);
        return true;
      })
      .catch(() => {
        context.dispatch("logout");
        return false;
      });
  },
  async logout(context) {
    if (context.getters.loggedIn) {
      Vue.$toast.warning("Tizimdan chiqdingiz.");
    }
    apiClient.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem("access_token");
    context.commit("DESTROY_TOKEN");
    await router.push({ name: "home" });
  },
  async login(context, data) {
    return await apiClient
      .post("user/login", data)
      .then((response) => {
        context.commit("SET_USER", response.data);
        context.commit("SET_TOKEN", response.data.token);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async order(context) {
    return await apiClient
      .post("order/status")
      .then((response) => {
        if (response.status === 201) {
          context.commit("SET_ORDER_ACCESS", false);
          context.commit("SET_ORDER_DATA", response.data);
        }
        if (response.status === 202) {
          context.commit("SET_ORDER_ACCESS", true);
          context.commit("SET_ORDER_INFO", response.data);
        }
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async clickData(context) {
    return await apiClient
      .post("click/get-data")
      .then((response) => {
        context.commit("SET_CLICK_DATA", response.data);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
};

const getters = {
  loggedIn(state) {
    return state.token !== null;
  },
  getUser(state) {
    return state.user;
  },
  getToken(state) {
    return state.token;
  },
  getTempToken(state) {
    return state.temp_token;
  },
  state(state) {
    return state;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
