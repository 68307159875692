const state = () => ({
  start: {
    texts: ["3", "2", "1", "Start"],
    time: 1,
  },
  audios: {
    alert: "/assets/audios/alert.mp3",
    success: "/assets/audios/success.mp3",
    fail: "/assets/audios/fail.mp3",
  },
});

const mutations = {};

const actions = {};

const getters = {
  getStart(state) {
    return state.start;
  },
  audios(state) {
    return state.audios;
  },
  game(state) {
    return state;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
