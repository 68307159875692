import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";

let config = {
  // baseURL: "http://oama.loc/api/",
  baseURL: "/api/",
  // baseURL: "http://192.168.0.107/api/",
  timeout: 10000,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
};

const apiClient = axios.create(config);

const authInterceptor = async (config) => {
  config.headers.Authorization = "Bearer " + store.getters["user/getToken"];
  config.headers["Accept-Language"] = store.getters["app/getLanguage"];

  await store.dispatch("app/inc_request");
  return config;
};

apiClient.interceptors.request.use(authInterceptor);

apiClient.interceptors.response.use(
  async (response) => {
    await store.dispatch("app/dec_request");
    return response;
  },
  async (error) => {
    await store.dispatch("app/dec_request");
    if (error.response.status === 401) {
      await store.dispatch("user/logout");
    }
    if (error.response.status === 422) {
      error.response.data.map((text) => {
        Vue.$toast.error(text);
      });
    }
    if (error.response.status === 402) {
      Vue.$toast.warning("To'lov talab qilinadi");
      await router.push({ name: "user_order" });
    }
    return Promise.reject(error);
  }
);

export default apiClient;
