import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let isLoggedIn = store.getters["user/loggedIn"];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next({ name: "login" });
    } else {
      store.dispatch("app/inc_request").then(() => {
        next();
      });
    }
  } else if (to.matched.some((record) => record.meta.onlyGuest)) {
    if (isLoggedIn) {
      next({ name: "home" });
    } else {
      store.dispatch("app/inc_request").then(() => {
        next();
      });
    }
  } else {
    store.dispatch("app/inc_request").then(() => {
      next();
    });
  }
});

router.afterEach(() => {
  store.dispatch("app/dec_request");
});

export default router;
