export default {
  date: function (value) {
    if (!value) return " ";
    let a = new Date(value * 1000);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    return date + " " + month + " " + year + " ";
  },
  datetime: function (value) {
    if (!value) return " ";
    let a = new Date(value * 1000);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    return (
      date + " " + month + " " + year + " " + hour + " : " + min + " : " + sec
    );
  },
  narx: function (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " so'm";
  },
  son: function (number) {
    if (typeof number === "number") {
      if (number >= 0) {
        return "+" + number;
      }
    }
    return number;
  },
};
